import { Col, Row, Skeleton, Spin, Statistic, Typography } from "antd";
import { IConfirmationProps } from "./Confirmation.interface";
import AlectifyText from "static/texts.json";
import { displayDateTime, getFullUserName, renderAmount } from "utils/helpers";
import { truncate } from "lodash";
import { useEffect, useState } from "react";
import { fetchExternalPMDetails } from "services/pm-external/pm-external.service";
import { ITask } from "redux/components/tasks";
import ChecklistStatus from "../procedure-detail/ChecklistStatus";
import { MESSAGES } from "constants/messages";
import "./style.scss";
import AlectifyTable from "../table";
import PreviewFile from "../preview-file";

const ReviewConfirmationModal: React.FC<IConfirmationProps> = (
  props: IConfirmationProps,
) => {
  const [workOrder, setWorkOrder] = useState<{
    data: ITask | null;
    fetching: boolean;
  }>({ data: null, fetching: false });

  const getWorkOrderDetail = async (id: string) => {
    setWorkOrder({ ...workOrder, fetching: true });
    try {
      const response = await fetchExternalPMDetails(id);
      setWorkOrder({ data: response.data, fetching: false });
    } catch (ex) {
      setWorkOrder({ ...workOrder, fetching: false });
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props?.pmExternal?.id) {
      getWorkOrderDetail(props.pmExternal.id);
    }
  }, [props.pmExternal?.id]);

  return (
    <Spin spinning={false}>
      {workOrder.fetching ? (
        <Skeleton active />
      ) : (
        <Row gutter={[20, 20]} className="card-container-review">
          <Col span={12}>
            <Statistic
              title={AlectifyText.WORK_TITLE}
              value={
                (props?.pmExternal?.workTitle &&
                  truncate(props?.pmExternal?.workTitle, {
                    length: 20,
                    omission: "...",
                  })) ||
                "-"
              }
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.WORK_ID}
              value={truncate(props?.pmExternal?.workId || "-")}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.COMPLETED_BY}
              value={
                workOrder.data?.reviewedBy
                  ? getFullUserName(workOrder.data?.reviewedBy as any)
                  : "-"
              }
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.COMPLETED_AT}
              value={
                displayDateTime(props?.pmExternal?.reviewedAt, true) || "-"
              }
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.DUEDATE}
              value={displayDateTime(props?.pmExternal?.dueDate) || "-"}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={24} className="heading-review-confirm">
            <Typography.Text strong>
              {MESSAGES.HEADINGS.DETAIL_OF_WORK}{" "}
            </Typography.Text>
          </Col>

          <Col span={12}>
            <Statistic
              title={AlectifyText.COMPLETION_DATE}
              value={
                displayDateTime(props?.pmExternal?.completionAt, false) || "-"
              }
              valueStyle={{ fontSize: 14 }}
            />
          </Col>

          {props.pmExternal?.procedure && (
            <Col span={12}>
              <ChecklistStatus
                completedSteps={
                  props.pmExternal.procedure
                    ?.procedureStepCheckedTotalCount as any
                }
                totalSteps={
                  props.pmExternal.procedure?.procedureStepTotalCount || 0
                }
              />
            </Col>
          )}

          <Col span={12}>
            <Statistic
              title={AlectifyText.ESTIMATED_HOURS}
              value={props?.pmExternal?.estimatedHours || "-"}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.ESTIMATED_COST}
              value={props?.pmExternal?.estimatedCost || "-"}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.SPARE_PARTS_USED}
              value={workOrder.data?.sparePartsUsed?.count || "-"}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={AlectifyText.SPARE_PARTS_COST}
              value={renderAmount(workOrder.data?.sparePartsUsed?.cost) || "-"}
              valueStyle={{ fontSize: 14 }}
            />
          </Col>
          <Col span={24} className="heading-review-confirm">
            <Typography.Text strong>Comments & Attachments</Typography.Text>
          </Col>
          <Col span={24}>
            {/* <Typography.Paragraph className="m-0">Comments:</Typography.Paragraph> */}
            {workOrder.data?.reviewComment?.text || "-"}
          </Col>
          <Col span={24}>
            <AlectifyTable
              showSearch={false}
              scroll={{ y: 200 }}
              columns={[
                {
                  dataIndex: "fileName",
                  title: "File Name",
                  render: (value, record) => {
                    return (
                      <PreviewFile
                        fileName={record.fileName}
                        fileUrl={record.filePath}
                        isActivevalue={record.isActive}
                      />
                    );
                  },
                },
              ]}
              dataSource={workOrder.data?.reviewDocuments as any}
              total={0}
              onDataFetch={() => {}}
              showPagination={false}
            />
          </Col>
        </Row>
      )}

      {/* <Row
        justify={"center"}
        align={"middle"}
        className="alectify-warning-box mt-20"
      >
        <Col span={24} className="text-align-center">
          <Row justify={"center"} align={"middle"}>
            <Col span={24}>
              <WarningIcon />
            </Col>

            <Col span={24}>
              <Typography.Text>
                {MESSAGES.PM_EXTERNAL.SUBMIT_F0R_REVIEW_WARNING}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="text-align-center mt-10">
          <AlectifyButton
            name="No"
            text="No"
            type="default"
            className="alectify-default-button"
            onClick={props.onCancel}
          />
          <AlectifyButton
            name="Yes"
            text="Yes"
            type="primary"
            className="ml-10"
            onClick={props.onConfirm}
          />
        </Col>
      </Row> */}
    </Spin>
  );
};
export default ReviewConfirmationModal;
