import {
  Col,
  Collapse,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import AlectifyText from "static/texts.json";
import { isEmpty } from "lodash";
import TextToLink from "components/shared/text-to-link";
import StatusCard from "components/shared/status-card/StatusCard";
import { PME_ACTION_REQUIRED_ENUM, TaskPriorityEnum } from "enums";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { StepProps } from "antd/lib";
import { displayDateTime, enumToTile, truncateText } from "utils/helpers";
import { PM_TYPES } from "redux/components/pm-external";
import AlectifyDescription from "components/shared/detailed-view/AlectifyDescriptionView";
import WorkOrderSpareParts from "components/shared/work-order-spare-parts";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import ProcedureDetail from "components/shared/procedure-detail";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import { ExportOutlined } from "@ant-design/icons";
import AlectifyButton from "components/shared/button";
import DrawWorkOrderSparePart from "components/shared/work-order-spare-parts/DrawWorkOrderSparePart";
import { ISparePartDraw } from "redux/components/spare-parts";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { fetchWorkOrderSparePartsHisotry } from "services/spare-parts/spare-parts.service";
import AlectifyTable from "components/shared/table";
import { ROUTES } from "routes/Routes.constants";
import { AssetPackageTag, AssetTag, GenericTag } from "components/shared/tags";
import { useDispatch } from "react-redux";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";
import { IParams } from "redux/interfaces";
import AlectifyCarousel from "components/carousel/AlectifyCarousel";
import WorkOrderMessages from "./WorkOrderMessages";
import { renderWorkOrderRepeats } from "./WorkOrder.helper";
import { PM_DETAIL_PRIORITY_COMPONENTS } from "components/shared/tasks-table/TasksTable.helper";
import { getPMEActionRequired } from "pages/work-order/WorkOrderCreateEdit.constants";
import { PM_STATUS_COMPONENTS } from "components/shared/tasks-table/effects/useTasksColumns";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICommentsState } from "redux/components/common";
import { fetchComments } from "services/comments/comments.service";
import { IWorkOrderStatusProps } from "./WorkOrderPMDetails.interface";
import "./style.scss";

const WorkOrderStatus: React.FC<IWorkOrderStatusProps> = (
  props: IWorkOrderStatusProps,
) => {
  const { Panel } = Collapse;
  const [FormInstance] = Form.useForm();
  const dispatch = useDispatch();
  // const { pmAttachments } = useSelector(
  //   (state: IRootState) => state.pmInternal,
  // );
  const [currentPage, setCurrentPage] = useState(1);

  const [activeHistoryAccordion, setActiveHistoryAccordion] = useState<
    string | string[]
  >(["active"]);
  const [comments, setComments] = useState<ICommentsState>({
    data: [],
    fetching: false,
    meta: {
      total_pages: 0,
      total_count: 0,
    },
  });

  const [history, setHistory] = useState<{
    data: ISparePartDraw[];
    total: number;
    fetching: boolean;
  }>({ fetching: false, data: [], total: 0 });

  // const openImagePreviewModal = () => {
  // ModalServiceInstance.open(AlectifyModal, {
  //   onCancel: () => {
  //     ModalServiceInstance.close(AlectifyModal, {
  //       name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
  //     });
  //   },
  //   name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
  //   title: "Preview",
  //   footer: null,
  //   children: <ViewImage imageUrl={url} />,
  // });
  // };

  useEffect(() => {
    if (props.taskId) {
      setCurrentPage(1);
      Promise.all([getCommentsHistory(1), fetchPmAttachments()]);
    }
  }, [props.taskId, props.activeTab]);

  useEffect(() => {
    if (props.taskId && !props.showComments) {
      setActiveHistoryAccordion([]);
      setComments({
        data: [],
        fetching: false,
        meta: {
          total_pages: 0,
          total_count: 0,
        },
      });
      setCurrentPage(1);
      getCommentsHistory(1);
    }
  }, [props.showComments]);

  const getCommentsHistory = async (page: number | string) => {
    if (props.subProjectId) {
      const params = {
        page: page,
        per_page: 10,
        ordering: "-created_at",
      };

      const response = await fetchComments(
        props.subProjectId,
        props.referenceId,
        params,
      );

      setComments((prev) => {
        const combinedData = [...prev.data, ...response.data];
        const uniqueData = Array.from(
          new Set(combinedData.map((item) => item.id)),
        ).map((id) => combinedData.find((item) => item.id === id));

        return {
          ...prev,
          data: uniqueData,
          meta: response.meta,
        };
      });
    }
  };

  const getStepsItems = (): StepProps[] => {
    const items: StepProps[] = [
      {
        title: "Created Work Order",
        description: (
          <Space direction="vertical" size={1}>
            <span className="status-description-step-date-text">
              {`${displayDateTime(props.task?.createdAt, false, false)}`}
            </span>
            {props.task?.createdAt &&
              props.task?.createdBy?.first_name +
                " " +
                props.task?.createdBy?.last_name}
          </Space>
        ),
        status: (!props.task?.createdAt && "error") || "finish",
      },
      {
        title: (
          <Space className="review-text">
            {props.task?.reviewedAt ? (
              <TextToLink
                text={<span>{AlectifyText.SUBMITTED_REVIEW}</span>}
                onClick={() => props.submittedInformationModal()}
              />
            ) : (
              <span>{AlectifyText.SUBMITTED_REVIEW}</span>
            )}
          </Space>
        ),
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completionAt &&
              `${
                props.task?.reviewedAt &&
                displayDateTime(
                  (props.task?.reviewedAt as any) || "-",
                  false,
                  false,
                )
              }`}
            {props.task?.reviewedBy &&
              props.task?.reviewedBy?.first_name +
                " " +
                props.task?.reviewedBy?.last_name}
          </Space>
        ),
        status: (props.task?.completionAt && "finish") || "error",
      },
    ];
    if (!props.task?.skippedAt) {
      items.push({
        title: AlectifyText.REVIEW_COMPLETED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completedAt &&
              `${
                (props.task?.completedAt &&
                  displayDateTime(
                    props.task?.completedAt as any,
                    false,
                    false,
                  )) ||
                "-"
              }`}
            {props.task?.completedAt &&
              props.task?.completedBy?.first_name +
                " " +
                props.task?.completedBy?.last_name}
          </Space>
        ),
        status: (props.task?.completedAt && "finish") || "error",
      });
    }
    if (props.task?.skippedAt) {
      items.push({
        title: AlectifyText.VOID,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.skippedAt &&
              `${
                (props.task?.skippedAt &&
                  displayDateTime(
                    props.task?.skippedAt as any,
                    false,
                    false,
                  )) ||
                "-"
              }`}

            {props.task?.skippedBy &&
              props.task?.skippedBy?.first_name +
                " " +
                props.task?.skippedBy?.last_name}
          </Space>
        ),
        status: (props.task?.skippedAt && "finish") || "error",
      });
    }
    return items;
  };

  // const handleFileUpload = async (file: any) => {
  //   const { task } = props;

  //   try {
  //     const formData = new FormData();
  //     if (task && !isEmpty(task)) {
  //       const taskFields = [
  //         { key: "taskCategory", value: task.taskCategory },
  //         { key: "pm_tracking", value: "asset" },
  //         { key: "assetLevel", value: task.asset ? "asset" : "area" },
  //         { key: "workTitle", value: task.workTitle },
  //         { key: "priority", value: task.priority || "-" },
  //         { key: "detail", value: task.detail || "-" },
  //         { key: "projectId", value: task.project?.id },
  //         { key: "subProjectId", value: task.subProject?.id },
  //         { key: "dayType", value: task.dayType },
  //         { key: "day", value: task.day },
  //         { key: "date", value: task.date },
  //         { key: "week", value: task.week },
  //         { key: "frequencyType", value: task.frequencyType?.toUpperCase() },
  //         { key: "frequency", value: task.frequency },
  //         { key: "isRecurring", value: task.isRecurring.toString() },
  //         {
  //           key: "changeAllFuturePM",
  //           value: task.isRecurring ? "true" : "false",
  //         },
  //         { key: "assetId", value: task.asset?.id },
  //         { key: "areaId", value: task.area?.id },
  //         { key: "procedureLibraryId", value: task.procedureLibraryId as any },
  //         { key: "image", value: file },
  //         {
  //           key: "dueDate",
  //           value: task.dueDate && DayJs(task.dueDate).format(),
  //         },
  //       ];

  //       taskFields.forEach(({ key, value }) => {
  //         if (value) formData.append(key, value);
  //       });
  //     }

  //     const response = await updatePrevMaintenances(task?.id || "-", formData);
  //     if (response.status) {
  //       message.success(MESSAGES.GENERAL_MESSAGES.IMAGE_UPLOADED);
  //     }
  //   } catch (error: any) {
  //     message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
  //     console.error(error);
  //   } finally {
  //     props.getPmExternalDetails?.(task?.id);
  //   }
  // };

  // const openAssetDetail = (record: ITask | null) => {
  //   if (!record?.isGeneric) {
  //     DrawerServiceInstance.open(AlectifyDrawer, {
  //       width: record?.asset?.name ? 420 : 900,
  //       title: truncate(record?.asset?.name || record?.area?.name, {
  //         length: 30,
  //         omission: "...",
  //       }),
  //       name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
  //       closable: true,
  //       closeIcon: <CloseOutlined />,
  //       onClose: () =>
  //         DrawerServiceInstance.close(AlectifyDrawer, {
  //           name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
  //         }),
  //       children: !isEmpty(record?.asset) ? (
  //         <AssetDetail
  //           taskRecord={record}
  //           subProjectId={record?.subProject?.id}
  //           assetId={record?.asset?.id}
  //           navigate={navigate}
  //           isDetailButton
  //           isUploadbutton
  //         />
  //       ) : (
  //         <PackageDetailAssets
  //           taskRecord={record}
  //           subProjectId={record?.subProject?.id || ""}
  //           assetPackageId={record?.area?.id || ""}
  //           navigate={navigate}
  //           isDetailsbutton
  //           isUploadbutton
  //         />
  //       ),
  //     });
  //   }
  // };

  const getHistory = async (options?: IPagination) => {
    try {
      if (props.task) {
        setHistory({ ...history, fetching: true });
        const params = {
          page: options?.page || PAGINATION.DEFAULT_START_PAGE,
          limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        };
        const response = await fetchWorkOrderSparePartsHisotry(
          props?.task?.id,
          params,
        );
        setHistory({
          fetching: false,
          data: response.data,
          total: response.meta?.totalItems || 0,
        });
      }
    } catch (error) {
      console.log(error);
      setHistory({ ...history, fetching: false });
    }
  };

  const openDrawForm = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 500,
      name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
      title: "Draw Spare Part",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
        });
      },
      onOk: FormInstance.submit,
      okText: "Submit",
      children: (
        <DrawWorkOrderSparePart
          getHistory={getHistory}
          workOrder={props.task as any}
          FormInstance={FormInstance}
          callback={() => props?.getPmExternalDetails(props.task?.id)}
        />
      ),
    });
  };

  const onOpenAssetsList = (totalCount: number) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.ASSET_NAME_MODAL,
      title: `Asset list of (${props.task?.workTitle})`,
      footer: null,
      width: 800,
      children: (
        <AlectifyTable
          onDataFetch={() => {}}
          dataSource={[
            ...(props.task?.assets.map((asset: any) => ({
              ...(asset.asset as any),
              type: "Tag",
            })) as any),
            ...(props.task?.areas.map((ar: any) => ({
              ...ar.area,
              type: "PackageRoom",
            })) as any),
          ]}
          total={totalCount}
          showPagination={false}
          columns={[
            {
              dataIndex: "name",
              width: 180,
              ellipsis: true,
              title: "Name",
              render: (value, assetRecord) => {
                const goToAssetDetails = () => {
                  let url: string;
                  if (assetRecord.type === "PackageRoom") {
                    url = `${ROUTES.SUB_PROJECT}/${props.task?.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                  } else {
                    url = `${ROUTES.SUB_PROJECT}/${props.task?.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                  }
                  window.open(url, "_blank");
                };
                return (
                  <TextToLink
                    text={
                      <Space>
                        <Tooltip title={value || "NA"}>
                          <span>{truncateText(value, 29)}</span>
                        </Tooltip>
                        <ExportOutlined />
                      </Space>
                    }
                    onClick={goToAssetDetails}
                  />
                );
              },
            },

            {
              title: AlectifyText.ASSET_TYPE,
              key: "asset_type",
              width: 140,
              render: (value, assetRecord) => {
                return (
                  <>
                    {assetRecord.type === "Tag" ? (
                      <AssetTag />
                    ) : assetRecord.type === "PackageRoom" ? (
                      <AssetPackageTag />
                    ) : (
                      <GenericTag />
                    )}
                  </>
                );
              },
            },
            {
              dataIndex: "location",
              title: AlectifyText.LOCATION,
              width: 160,
              ellipsis: true,
              render: (location, assetRecord) => {
                return (
                  <Tooltip title={location || "NA"}>
                    {truncateText(location || "-", 30)}
                  </Tooltip>
                );
              },
            },
            {
              dataIndex: "description",
              title: "description",
              width: 200,
              ellipsis: true,
              render: (description, assetRecord) => {
                return (
                  <Tooltip title={description || "NA"}>
                    {truncateText(description || "-", 37)}
                  </Tooltip>
                );
              },
            },
          ]}
        />
      ),
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ASSET_NAME_MODAL,
        }),
    });
  };

  const fetchPmAttachments = async (page?: any) => {
    try {
      const params = {
        page: page || 1,
        limit: 5,
        signedUrls: true,
        fileType: "media",
      };
      await dispatch(getPmInternalAttachments(props.taskId, params as IParams));
    } catch (err) {
      console.log(err);
    }
  };

  const loadMoreComments = async () => {
    const hasMore = comments.data.length < (comments.meta?.total_count || 0);
    if (hasMore && props.subProjectId) {
      const nextPage = currentPage + 1;
      await getCommentsHistory(nextPage);
      setCurrentPage(nextPage);
    }
  };

  const onChangeHistoryAccordion = (key: string | string[]) => {
    setActiveHistoryAccordion(key);
  };

  return (
    <div id="additionalIncludeId1">
      <Spin spinning={props.loader || props.procedureLoading}>
        <div
          className="work-order-status-container"
          id="scrollableDivMessagesPanel"
        >
          <InfiniteScroll
            dataLength={comments.data.length}
            next={
              !isEmpty(activeHistoryAccordion) ? loadMoreComments : () => {}
            }
            scrollThreshold={!isEmpty(activeHistoryAccordion) ? 0.9 : undefined}
            hasMore={
              !isEmpty(activeHistoryAccordion) &&
              comments.data.length < (comments.meta?.total_count || 0)
            }
            loader={
              !isEmpty(activeHistoryAccordion) && (
                <Skeleton avatar paragraph={{ rows: 1 }} active />
              )
            }
            scrollableTarget="scrollableDivMessagesPanel"
          >
            <div
              className="status-asset-section"
              style={{
                backgroundColor: props.task?.isFuture ? "#feefd766" : "inherit",
              }}
            >
              <Row wrap={false}>
                <Col flex="400px" id="idToExclude">
                  <div className="alectify-pm-basic-info-image-card">
                    <AlectifyCarousel
                      preview={true}
                      details={props.task}
                      fetchMoreAttachments={fetchPmAttachments}
                      thumbnailLimit={5}
                      recordID={props.taskId}
                      videoWidth={"24.4rem"}
                      videoHeight={"16rem"}
                    />
                    {/* <div
                    className={`alectify-pm-basic-info-image ${
                      props.task?.imageUrl ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      props.task?.imageUrl && onOpenTableFilterModal()
                    }
                    style={{
                      backgroundImage: `url(${
                        props.task?.imageUrl || NO_IMAGE
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  /> */}

                    {/* <Upload
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      const { fileList } = info;
                      handleFileUpload(fileList[0].originFileObj);
                    }}
                    fileList={[]}
                    beforeUpload={() => false}
                  >
                    <div className="edit-profile-text" id="idToExclude">
                      {props.task?.imageUrl ? "Change Image" : "Add Image"}
                    </div>
                  </Upload> */}
                  </div>
                </Col>
                <Col flex="auto">
                  <div className="status-work-order-detail-section">
                    {!props.loader ? (
                      <div>
                        <span className="pm-detail-title-text">
                          {props.task?.workTitle}
                        </span>
                        <Space className="space-container">
                          {props.task?.status &&
                            PM_STATUS_COMPONENTS[props.task?.status]}

                          <span className="critical-chip">
                            {
                              PM_DETAIL_PRIORITY_COMPONENTS[
                                props.task?.priority as TaskPriorityEnum
                              ]
                            }
                          </span>

                          <span className="alectify-pm-info-detail-value">
                            <AlectifyChip
                              text={`Due Date: ${
                                displayDateTime(props.task?.dueDate) || "-"
                              }`}
                              backgroundColor={"#ffebeb"}
                              borderColor={"#f94c4c"}
                              textColor={"#f94c4c"}
                            />
                          </span>

                          {props.task?.pmType && (
                            <span className="alectify-pm-info-detail-value">
                              <AlectifyChip
                                text={enumToTile(
                                  props.task?.pmType === PM_TYPES.PM_EXTERNAL
                                    ? props.task?.taskCategory
                                    : props.task?.pmType || "",
                                )}
                                backgroundColor={"#e8f1fd"}
                                borderColor={"#0954f1"}
                                textColor={"#0954f1"}
                              />
                            </span>
                          )}

                          <div className={`site-asset-container`}>
                            {!props.task?.isGeneric ? (
                              <>
                                {!isEmpty(props.task?.assets) ||
                                !isEmpty(props.task?.areas)
                                  ? (() => {
                                      const assetCount = (
                                        props.task?.assets || []
                                      ).length;
                                      const areaCount = (
                                        props.task?.areas || []
                                      ).length;
                                      const totalCount = assetCount + areaCount;

                                      if (totalCount === 1) {
                                        // Single asset or area
                                        if (assetCount === 1) {
                                          return (
                                            <Space>
                                              <span>Sub Asset:</span>
                                              <TextToLink
                                                text={
                                                  (props.task?.assets[0] as any)
                                                    ?.asset?.name || "-"
                                                }
                                                onClick={() => {
                                                  const assetId = (
                                                    props.task?.assets[0] as any
                                                  )?.asset?.id;
                                                  const subProjectId =
                                                    props.task?.subProject?.id;
                                                  const url = `${ROUTES.SUB_PROJECT}/${subProjectId}/timeline/asset/${assetId}?page=timeline`;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            </Space>
                                          );
                                        } else if (areaCount === 1) {
                                          return (
                                            <Space>
                                              <span>Parent Asset:</span>
                                              <TextToLink
                                                text={
                                                  (props.task?.areas[0] as any)
                                                    ?.area?.name || "-"
                                                }
                                                onClick={() => {
                                                  const areaId = (
                                                    props.task?.areas[0] as any
                                                  )?.area?.id;
                                                  const subProjectId =
                                                    props.task?.subProject?.id;
                                                  const url = `${ROUTES.SUB_PROJECT}/${subProjectId}/timeline/asset-package/${areaId}?page=timeline`;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            </Space>
                                          );
                                        }
                                      } else if (totalCount > 1) {
                                        // Multiple assets or areas: show first one + count
                                        // const firstItemName =
                                        //   assetCount > 0
                                        //     ? (props.task?.assets[0] as any)?.asset
                                        //         ?.name || "First Asset"
                                        //     : (props.task?.areas[0] as any as any)
                                        //         ?.area?.name || "First Area";

                                        const remainingCount = totalCount;
                                        return (
                                          <Space>
                                            <span>Multiple Assets:</span>
                                            <TextToLink
                                              text={`[${remainingCount} Assets]`}
                                              onClick={() =>
                                                onOpenAssetsList(totalCount)
                                              }
                                            />
                                          </Space>
                                        );
                                      }

                                      // Default fallback
                                      return "-";
                                    })()
                                  : "-"}
                              </>
                            ) : (
                              <span>{AlectifyText.NO_ASSET}</span>
                            )}
                          </div>
                        </Space>
                        <span className="future-info">
                          {props.task?.isFuture ? (
                            <strong className="future-text">
                              Future Event. There are preceding work orders
                              before this event.
                            </strong>
                          ) : (
                            <></>
                          )}
                        </span>
                        <span className="sub-info">
                          {renderWorkOrderRepeats(props.task) != null ? (
                            <>
                              <strong className="recurring-text">
                                Recurring:
                              </strong>{" "}
                              {renderWorkOrderRepeats(props.task)}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                        <span className="work_id-container">
                          {AlectifyText.WORK_ID}: {props.task?.workId}
                        </span>
                        <div className="description-body">
                          <AlectifyDescription
                            detail={props.task?.detail || ""}
                            height={"80px"}
                          />
                        </div>
                      </div>
                    ) : (
                      <Skeleton />
                    )}
                  </div>

                  <div
                    id="idToExclude"
                    className="border-title d-flex justify-content-space-between align-items-center"
                  >
                    <Typography.Title level={5} className="mt-5">
                      {AlectifyText.WORK_ORDER_STATUS}
                    </Typography.Title>

                    {/* <span>{props.actionButton}</span> */}
                  </div>

                  <div id="idToExclude" className="status-cards">
                    <Row gutter={[6, 6]} align="middle" className="pR-10">
                      <Col xs={24} sm={12} md={8}>
                        <StatusCard
                          text="Assignees"
                          helperText={
                            getPMEActionRequired(props.task as any) ===
                            PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                              ? "(Action Required)"
                              : ""
                          }
                          users={
                            props.task?.assignees?.map(({ user }) => user) || []
                          }
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <StatusCard
                          text="Approvers"
                          helperText={
                            getPMEActionRequired(props.task as any) ===
                            PME_ACTION_REQUIRED_ENUM.APPROVERS
                              ? "(Action Required)"
                              : ""
                          }
                          users={
                            props.task?.approvers?.map(({ user }) => user) || []
                          }
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <StatusCard
                          text="Team members"
                          users={
                            (!isEmpty(props.task?.team) &&
                              props.task?.team.projectTeamMembers.map(
                                (d) => d.user,
                              )) ||
                            (!isEmpty(props.task?.teamMembers) &&
                              props.task?.teamMembers?.map(
                                (d: any) => d.user,
                              )) ||
                            []
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div
                    id="idToExclude"
                    className="pm-detail-info-steps mb-10 mr-10"
                  >
                    <Steps
                      current={3}
                      status={"process"}
                      items={getStepsItems()}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div id="idToExclude" className="accordion-customs">
              <Collapse>
                <Panel
                  header={
                    <div className="d-flex justify-content-space-between align-items-center">
                      {AlectifyText.SPARE_PARTS} (
                      {props.task?.sparePartsUsed?.count || "0"})
                      <AlectifyButton
                        text="Draw Spare Part"
                        type="primary"
                        onClick={() => openDrawForm()}
                      />
                    </div>
                  }
                  key="1"
                >
                  {props.task && (
                    <WorkOrderSpareParts
                      workOrderDetailPage
                      workOrderGetHistory={getHistory}
                      workOrderSParePartsHistory={history}
                      record={props.task as any}
                      showDrawSparePartsButton={false}
                      callback={() =>
                        props?.getPmExternalDetails(props.task?.id)
                      }
                    />
                  )}
                </Panel>
              </Collapse>
            </div>

            {!isEmpty(props.procedure) && (
              <div>
                <Collapse
                  className="mt-10"
                  activeKey={props.proceduresActiveKey}
                  onChange={props.onChangeProceduresActiveKey}
                >
                  <Panel
                    header={
                      <div>
                        {AlectifyText.PROCEDURE_STEPS} (
                        {countCheckedSteps(
                          props.task?.procedure?.procedureSteps || [],
                        )}
                        )
                      </div>
                    }
                    key="2"
                  >
                    {isEmpty(props?.procedure) ? (
                      <AlectifyEmpty description="No data" />
                    ) : (
                      <ProcedureDetail
                        isDetailPage
                        procedure={props.procedure}
                        taskId={props.taskId}
                        subProjectId={props.subProjectId}
                        referenceId={props?.referenceId}
                        isDisabled={props.isDisabled}
                        getPmExternalDetails={props.getPmExternalDetails}
                      />
                    )}
                  </Panel>
                </Collapse>
              </div>
            )}

            <div id="idToExclude" className="mt-10 history-panel">
              <Collapse
                defaultActiveKey={"active"}
                onChange={onChangeHistoryAccordion}
                activeKey={activeHistoryAccordion}
              >
                <Panel
                  header={`Messaging (${comments?.meta?.total_count || 0})`}
                  key="active"
                >
                  {props.task && (
                    <WorkOrderMessages
                      comments={comments?.data || []}
                      subProjectId={props.subProjectId || "-"}
                      referenceId={props.referenceId}
                    />
                  )}
                </Panel>
              </Collapse>
            </div>
          </InfiniteScroll>
        </div>
      </Spin>
    </div>
  );
};

export default WorkOrderStatus;
