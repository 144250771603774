import { useState } from "react";
import dayjs from "dayjs";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import { Col, DatePicker, Form, List, Row, Space, Typography } from "antd";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import TextEditor from "components/text-editor/TextEditor";
import { IIncidentCommentBoxProps } from "./IncidentDetails.interface";
import { createNewIncidentComment } from "services/incident-reports/incident-reports.service";
import { IR_FIELD_NAMES } from "../incident-report-creation/IncidentReportCreateEdit.constants";
import { isEmpty } from "lodash";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { WarningIcon } from "components/icons";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import AlectifyTable from "components/shared/table";
import { IUser } from "redux/components/Auth";
import AvatarGroup from "components/shared/avatar-group";
import { isExternalUser } from "utils/helpers";
import AttachmentIcon from "components/icons/AttachmentIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import ExternalTag from "components/shared/tags/ExternalTag";

const IncidentCommentBox: React.FC<IIncidentCommentBoxProps> = (
  props: IIncidentCommentBoxProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const formatDateIfValid = (date: any) => {
    return date && dayjs(date).isValid() ? dayjs(date).format() : undefined;
  };
  // create state to store the files that are selected
  const [fileList, setFileList] = useState<File[]>([]); // Store selected files

  const handleChange = (info: any) => {
    const file = info.file.originFileObj || info.file;
    if (
      file &&
      !fileList.some((existingFile) => existingFile.name === file.name)
    ) {
      // Add file only if it does not already exist in the fileList
      setFileList((prevList) => {
        return [...prevList, file];
      });
    }
  };

  const handleRemove = (fileToRemove: File) => {
    setFileList((prevList) => {
      const updatedList = prevList.filter(
        (file) => file.name !== fileToRemove.name,
      );
      return updatedList;
    });
  };
  const onCancel = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_EMAIL_TO_CLIENT_WARNING_MODAL,
    });
  };
  const onSubmitHandler = (valus: any) => {
    if (!isEmpty(props.incidentDetails)) {
      const getTeamUsers = props.incidentDetails?.team
        ? props.incidentDetails.team.projectTeamMembers.map((d: any) => d.user)
        : props.incidentDetails?.teamMembers.map((d: any) => d.user);
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.INCIDENT_EMAIL_TO_CLIENT_WARNING_MODAL,
        title: AlectifyText.CONFIRMATION,
        footer: false,
        onCancel,
        width: 600,
        children: (
          <ConfirmationModal
            message={
              <Space direction="vertical" className="mt-5 mb-10">
                <strong>
                  Are you sure, This action will send an email to all team
                  members (clients)?
                </strong>
              </Space>
            }
            icon={WarningIcon}
            onCancel={onCancel}
            onConfirm={() => {
              onCancel();
              onSubmit(valus);
            }}
            extraInformation={
              <AlectifyTable
                showPagination={false}
                bordered={false}
                size="small"
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    render: (value, record: IUser) => (
                      <Space>
                        <AvatarGroup users={[record]} showImage />
                        <span>
                          {record.first_name} {record.last_name}
                        </span>
                        {isExternalUser(record) ? "(External)" : ""}
                      </Space>
                    ),
                  },
                  {
                    title: "Email",
                    dataIndex: "email",
                  },
                  {
                    title: "User Type",
                    dataIndex: "user_type",
                    render: (value, record: IUser) => (
                      <span>
                        {isExternalUser(record) ? <ExternalTag /> : "-"}
                      </span>
                    ),
                  },
                ]}
                dataSource={
                  getTeamUsers.length > 0
                    ? getTeamUsers
                    : props.incidentDetails?.teamMembers || []
                }
                total={0}
                onDataFetch={() => {}}
              />
            }
          />
        ),
      });
    }
  };
  const onSubmit = async (values: any) => {
    setLoader(true);
    try {
      let formData = new FormData();
      const payload = {
        ...values,
        callAt: formatDateIfValid(values.callAt),
        nextUpdateAt: formatDateIfValid(values.nextUpdateAt),
        description: ".",
      };
      if (!isEmpty(fileList)) {
        fileList.map((fileList: any) => {
          formData.append("files", fileList as Blob);
          return [];
        });
      }
      for (let key in payload) {
        if (key === "callAt" || key === "nextUpdateAt") {
          if (!isEmpty(payload[key])) {
            formData.append(key, formatDateIfValid(payload[key]) || "");
          }
        } else {
          formData.append(key, payload[key] || "");
        }
      }
      const response = await createNewIncidentComment(
        props.incidentId,
        formData,
      );
      if (response.status) {
        props.FormInstance.resetFields();
        props.callback && props.callback();
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onSubmitHandler}
      form={props.FormInstance}
    >
      <div className="alectify-incident-section-card">
        <Row justify={"center"} align={"top"} gutter={10}>
          <Col span={12}>
            <Form.Item
              name={IR_FIELD_NAMES.TIME_OF_CALL}
              label={"Date/Time of Call (24 hrs)"}
              className="mb-5"
            >
              <DatePicker
                showTime={
                  {
                    format: "HH:mm",
                    defaultValue: dayjs("00:00", "HH:mm"),
                  } as any
                }
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={IR_FIELD_NAMES.NEXT_UPDATE}
              label={"Next Update (24 hrs)"}
              className="mb-5"
            >
              <DatePicker
                showTime={
                  {
                    format: "HH:mm",
                    defaultValue: dayjs("00:00", "HH:mm"),
                  } as any
                }
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </Col>
          {/*  <Col span={8}>
            <AlectifyInput
              name={IR_FIELD_NAMES.SYSTEM_STATE}
              label={"System State"}
              type="text"
              className="mb-5"
              placeholder=""
            />
          </Col> */}
          <Col span={12}>
            <Form.Item
              name={IR_FIELD_NAMES.SYSTEM_STATE}
              label={"System State"}
              className="m-0 incident-detail-reply-description "
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    "System State",
                  ),
                },
              ]}
            >
              <TextEditor
                name={IR_FIELD_NAMES.SYSTEM_STATE}
                initialValue={""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    IR_FIELD_NAMES.SYSTEM_STATE,
                    value,
                  );
                }}
                customClass="wo-description incident-reply-description m-0"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={"Actions"}
              name={IR_FIELD_NAMES.ACTIONS}
              className="m-0 incident-reply-description"
            >
              <TextEditor
                name={IR_FIELD_NAMES.ACTIONS}
                initialValue={""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    IR_FIELD_NAMES.ACTIONS,
                    value,
                  );
                }}
                customClass="wo-description m-0"
              />
            </Form.Item>
          </Col>
          {/*  <Col span={12}>
            <Form.Item
              name={IR_FIELD_NAMES.DESCRIPTION}
              label={AlectifyText.DESCRIPTION}
              className="m-0 incident-detail-reply-description "
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.DESCRIPTION,
                  ),
                },
              ]}
            >
              <TextEditor
                name="description"
                initialValue={""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    IR_FIELD_NAMES.DESCRIPTION,
                    value,
                  );
                }}
                customClass="wo-description incident-reply-description m-0"
              />
            </Form.Item>
          </Col> */}
          <Col span={12} className="text-align-left">
            {/* <AlectifyButton
              name="pme-detail-action-btn"
              text={"Upload Files"}
              className="alectify-default-button"
              icon={<AttachmentIcon />}
              type="default"
              loading={loader}
            /> */}
            <input
              type="file"
              style={{ display: "none" }}
              multiple
              ref={(input) => {
                if (input) {
                  input.onchange = (event: any) => {
                    const files = event.target.files;
                    if (files) {
                      // save files in state
                      Array.from(files).forEach((file) => {
                        handleChange({ file: { originFileObj: file } });
                      });
                    }
                  };
                }
              }}
            />
            <AlectifyButton
              name="upload-files-btn"
              text={"Upload Files"}
              className="alectify-default-button"
              icon={<AttachmentIcon />}
              type="default"
              loading={loader}
              onClick={() => {
                const fileInput = document.querySelector(
                  'input[type="file"]',
                ) as HTMLInputElement;
                if (fileInput) {
                  fileInput.click();
                }
              }}
            />
          </Col>
          <Col span={12} className="text-align-right">
            <AlectifyButton
              name="pme-detail-action-btn"
              text={"Reply"}
              onClick={props.FormInstance.submit}
              type="primary"
              loading={loader}
            />
          </Col>
          <Col span={24}>
            {fileList.length > 0 && (
              <>
                <Typography.Title level={5} className="mt-15">
                  Selected Files
                </Typography.Title>
                <List
                  bordered
                  dataSource={fileList}
                  renderItem={(file) => (
                    <List.Item
                      actions={[
                        <span
                          key="remove"
                          onClick={() => handleRemove(file)}
                          style={{ color: "#ff4d4f", cursor: "pointer" }}
                        >
                          <DeleteIcon />
                        </span>,
                      ]}
                    >
                      <span>{file.name}</span>
                    </List.Item>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default IncidentCommentBox;
